<template>
	<Summary
		form_ref="gatherQuestionnaireForm"
		:questionnaire_name="this.$defines.QUESTIONNAIRE.q70"
	></Summary>
</template>

<script>
import Summary from "@/components/research/modules/_common/summary/Index.vue";

export default {
	name: "GatherSourcesSummaryPage",

	components: {
		Summary,
	},
};
</script>
